.page_container {
	padding-bottom:40px;
	background:#FFF;
}
.separator {
}
.block {padding-bottom:50px;
}
#main_slider {padding-bottom:70px;
}

.planning {
	padding-bottom:50px;
	text-align:center;
}
.planning a {
	display:block;
	margin-bottom:15px;
	padding:0;
	color:#3a3a3a;
}
.planning a span.img_icon {
	display:block;
	width:70px;
	height:70px;
	margin:0 auto 14px auto;
}
.planning a span.icon1 {
	/* background-image:url(../../public/img/icon1.png); */
	background-position:left top;
	background-repeat:no-repeat;
}
.planning a:hover span.icon1 {background-position:left bottom;
}
.planning a span.icon2 {
	/* background-image:url(../../public/img/icon2.png); */
	background-position:left top;
	background-repeat:no-repeat;
}
.planning a:hover span.icon2 {background-position:left bottom;
}
.planning a span.icon3 {
	/* background-image:url(../../public/img/icon3.png); */
	background-position:left top;
	background-repeat:no-repeat;
}
.planning a:hover span.icon3 {background-position:left bottom;
}
.planning a span.icon4 {
	/* background-image:url(../../public/img/icon4.png); */
	background-position:left top;
	background-repeat:no-repeat;
}
.planning a:hover span.icon4 {background-position:left bottom;
}
.planning a span.link_title {
	display:block;
	line-height:24px;
	font-size:20px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight:800;
	padding-bottom:8px;
	color:#313131;
}
.planning a:hover {text-decoration:none;
}

[class*="span"] img, [class*="span"] iframe {max-width:100%;
}

.post_carousel {
	padding-bottom:12px;
	color:#1e1e1e;
}
.post_carousel .title_t, .news_t {
	line-height:20px;
	padding:14px 0 5px 0;
	font-size:17px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight:400;
	color:#d00b01;
}
.post_carousel .title_t a, .news_t a {
	font-size:17px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight:400;
	color:#d00b01;
}
.post_carousel .title_t a:hover, .news_t a:hover {
	text-decoration:none;
	color:#313131;
}
.post_meta, .news {
	color:#9d9d9d;
	font-style:italic;
	padding-bottom:8px;
}
.post_meta span, news span {padding:0 7px;
}
.post_meta a, .news a {
	color:#9d9d9d;
	font-style:italic;
}
.post_meta a:hover, .news a:hover {
	color:#d00b01;
	text-decoration:none;
}
.arrow_link {
	font-style:italic;
	color:#d00b01;
}
.arrow_link:hover {
	text-decoration:none;
	color:#1e1e1e;
}
.block:last-child {padding-bottom:0;
}
.block ul, .follow_us ul, #footer ul {
	padding:0;
	margin:0;
	list-style:none;
}
.block li, #footer li {
	padding:0;
	margin:0;
}

/* Portfolio */
.portfolio_zoom, .portfolio_link {
	display:none;
	width:33px;
	height:33px;
	position:absolute;
	left:50%;
	top:50%;
	margin:-13px 0 0 -33px;
	/* background-image:url(../../public/img/zoom.png); */
	background-position:0 -33px;
	background-repeat:no-repeat;
	z-index:2;
}
.portfolio_link {
	margin:-13px 0 0 1px;
	/* background-image:url(../../public/img/link.png); */
}
.portfolio_zoom:hover, .portfolio_link:hover {background-position:0 0;
}
.portfolio_zoom a, .portfolio_link a {
	display:block;
	width:33px;
	height:33px;
	text-indent:-9999px;
}
.hover_img:hover .portfolio_zoom, .hover_img:hover .portfolio_link, .hover_img:hover .item_description { display:block;
}

.item_description {
	text-align:center;
	color:#313131;
}
.item_description h6 {
	font-weight:800;
	margin:10px 0 10px 0;
	text-transform:uppercase;
}

#portfolio_carousel {margin-bottom:20px;
}
#portfolio_carousel img {border-radius:3px;
}
#portfolio_carousel .carousel-control {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 33px;
  height: 33px;
  margin-top: -17px;
  /* background-image:url(../../public/img/portfolio_nav.png); */
  background-position:0 -33px;
  background-repeat:no-repeat;
  outline:none;
}
#portfolio_carousel .carousel-control.right {
	background-position:-102px -33px;
	left:auto;
	right:15px;
}
#portfolio_carousel .carousel-control.right:hover {
	background-position:-102px 0;
}
#portfolio_carousel .carousel-control:hover {background-position:0 0;
}
.pad25 { padding-top:25px;
}
.pad5 { padding-top:5px;
}
.marg20 { margin-top:20px !important;
}

/* Galeria Section */
#galeria {
	/* padding: 0 0 60px 0; */
	background: #444 url(../../public/img/flor10.jpg) center center no-repeat fixed;
	background-size: cover;
}
#galeria .overlay {
	/* padding: 80px 0; */
	padding-bottom: 20px;
	background: rgba(0, 0, 0, 0.7);
}
#galeria .section-title {
	/* background: #444 url(../../public/img/gallery-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
	margin-bottom: 50px;
}
#galeria .section-title h2 {
	color: #fff;
}
#galeria .section-title h3,h4 {
	color: #fff;
}
.categories {
	padding-bottom: 30px;
	text-align: center;
}
ul.cat li {
	display: inline-block;
}
ol.type li {
	display: inline-block;
	margin: 0 10px;
	padding: 20px 0;
}
ol.type li a {
	color: #999;
	font-weight: 500;
	font-size: 14px;
	padding: 12px 24px;
	background: #eee;
	border: 0;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
ol.type li a.active {
	color: #fff;
	background-color: #7e4b2c;
}
ol.type li a:hover {
	color: #fff;
	background-color: #7e4b2c;
}
.isotope-item {
	z-index: 2
}
.isotope-hidden.isotope-item {
	z-index: 1
}
.isotope, .isotope .isotope-item {
	/* change duration value to whatever you like */
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	transition-duration: 0.8s;
}
.isotope-item {
	margin-right: -1px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.isotope {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	transition-property: height, width;
}
.isotope .isotope-item {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	transition-property: transform, opacity;
}
.galeria-item {
	margin: 15px 0;
	color: white;
}
.galeria-item .hover-bg {
	overflow: hidden;
	position: relative;
	cursor: pointer;
}
.galeria-item .hover-bg:before {
	display: block;
	content: '';
	position: absolute;
	top: 6px;
	right: 6px;
	bottom: 6px;
	left: 6px;
	border: 1px solid rgba(255, 255, 255, 0.6);
}
.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: rgba(0, 0, 0, 0.6);
	padding: 30% 0 0 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 17px;
	letter-spacing: 0.5px;
	font-weight: 500;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
}
