/*

Author: Bootstrapthemes
Author URI: http://bootstrapthemes.co

*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body {
  background-color: #1e2e3b;
  font-size: 15px;
  color: #565656;
  width: 100%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-family: "roboto", sans-serif;
  font-weight: 300;
}

/*==========================================
PRE LOADER
==========================================*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fefefe;
  z-index: 99999;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

.loaded {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../../public/img/preloading.gif);
  background-repeat: no-repeat;
  background-position: center;
  -moz-background-size: cover;
  background-size: cover;
  margin: -20px 0 0 -20px;
}
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}
a,
button,
a span {
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.btn.extra-width {
  padding: 13px 40px;
}
.btn:focus,
.btn:active {
  outline: inherit;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*    direction: rtl;*/
}
p {
  margin: 0 0 15px;
}
.clear {
  clear: both;
}
ol,
ul {
  list-style: none;
  padding: 0;
}
img {
  max-width: 100%;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  outline: none;
}
.form-control {
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 16px;
  height: 34px;
  max-width: 90%;
  background: #323232;
}
.form-control:focus {
  border-color: #d6962c;
}
h1,
h2,
h3 {
  font-family: "Monotype Corsiva", cursive;
  font-weight: 300;
  margin: 0;
}
h4,
h5 {
  font-weight: 300;
  margin: 0;
  line-height: 2rem;
  font-family: "Monotype Corsiva", cursive;
}
h1 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 300;
}
h2 {
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 0.9rem;
}

h3 {
  font-family: "Monotype corsiva", cursive;
  font-weight: 300;
  margin-bottom: 0.9rem;
  font-size: 2.375rem;
  line-height: 2.5rem;
}
h4 {
  font-size: 1.875rem;
  line-height: 2.2rem;
  margin-bottom: 1.1rem;
  font-family: "Monotype Corsiva", cursive;
}
h5 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
h6 {
  font-size: 1rem;
  margin-bottom: 0.9rem;
}
p {
  line-height: 1.5rem;
  font-size: 1.1rem;
}
p:last-child {
  margin-bottom: 0px;
}

/*.home-wrap h1,*/
.home-wrap h2,
.home-wrap h3,
.home-wrap h4,
.home-wrap h5,
.home-wrap p {
  color: #ffffff;
  font-family: "roboto", sans-serif;
}
.text {
  color: #333333;
}
.colorstext {
  color: #f56363;
}
.coloricon i {
  color: #f56363;
}
.colorsbg {
  background: #f56363;
  color: #ffffff;
}
.colorsbg .separator2 {
  border: 2px solid;
}
.colorsbg .separator_wrap::after,
.colorsbg .separator_wrap::before {
  background-color: #ffffff;
}
.lightbg {
  background-color: #f9f9f9;
}
.transition {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.policy {
	/* padding-top: 900px 0; */
	background: #2e5366;
	color: white;
}
/*
Section
*/
section {
  margin: 0;
}
.sections {
  padding-top: 100px;
  /* padding-bottom: 100px; */
  position: relative;
  overflow: hidden;
}
.sections.footer {
  padding-bottom: 80px;
}
section .subtitle h5 {
  margin-top: 10px;
  font-size: 1.3rem;
  font-family: "roboto", sans-serif;
}
.parallax-section {
  max-width: 100%;
  color: #ffffff;
}
section .title-half h2 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 300;
  margin-bottom: 1.4rem;
}
section .subtitle-half h5 {
  font-weight: 100;
  font-size: 17px;
}
/*
section:nth-of-type(odd) {
    background-color: #F9F9F9;
}*/
.fw-special-title-half {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 100;
  /*    margin-bottom: 10px;*/
}
.heading {
  margin-bottom: 4rem;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  min-height: 650px;
}
.overlay-fluid-block {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.home-overlay-fluid {
  background-color: rgba(41, 41, 41, 0.68);
  width: 80%;
  margin: auto;
}
.overlay-img {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #ffffff;
}
.no-padding {
  padding: 0 !important;
}
.fluid-blocks-col {
  padding: 70px 40px 0 80px;
}
.fluid-blocks-col-right {
  padding: 70px 80px 0 40px;
}
/*
Separator
*/
.separator {
  height: 1px;
  width: 150px;
  margin: auto;
  background: #f56363;
}
.separator-left {
  height: 1px;
  width: 150px;
  background: #f56363;
}
.separator_wrap {
  text-align: center;
}
.separator_wrap-left {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 30px;
}
.separator_wrap::after,
.separator_wrap::before {
  background-color: #f56363;
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 90px;
  height: 1px;
  margin-top: 2px;
}
.separator_wrap-left::after {
  background-color: #f56363;
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 80px;
  height: 1px;
  margin-top: 2px;
}
.separator2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 2px solid #f56363;
  transform: rotate(45deg);
  margin-right: 4px;
}
/*
Button
*/
.rci-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  /* background-color: #ff5c5c; */
  background-color: #98672c;
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

.rci-button:hover {
  background-color: #74330d;
}

.rci-button:active {
  background-color: #98672c;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.page_container {
  padding-bottom: 40px;
  background: #fff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary.disabled:hover {
  color: #ffffff;
  background-color: #d6962c;
  border-color: #d6962c;
}
.btn {
  margin-right: 15px;
  border-radius: 0px;
}
/*.btn-default:active, .btn-default:hover  {
    background: transparent;
}*/
.btn-lg,
.btn-group-lg > .btn {
  padding: 8px;
  font-size: 1.2rem;
  border-radius: 4px;
  width: 200px;
  border: 1px solid #fff;
  text-transform: none;
  transition: 0.6s;
  margin-top: 30px;
  color: #fff;
}
button.btn-lg {
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 4px;
  width: 200px;
  border: 1px solid #fff;
  text-transform: none;
  transition: 0.6s;
  margin-top: 30px;
  color: #fff;
  display: inline-block;
  background: transparent;
  line-height: 1.5em;
}

button.btn-lg:hover {
  background: #d6962c;
  color: #fff;
}

.btn-md,
.btn-group-md > .btn {
  padding: 8px 20px;
  font-size: 1.2rem;
  border-radius: 30px;
}

.btn-primary {
  color: #ffffff;
}
.btn-primary:active,
.btn-primary:hover {
  background: #d6962c;
}

.btn-default {
  color: #ffffff;
  border: 2px solid #ffffff;
  background-color: transparent;
}
.btn-default:before,
.btn-default:after {
  background-color: #ffffff;
}
.btn-default:focus {
  background-color: #ffffff;
  border-color: transparent;
}
.btn-default:hover {
  border-color: #ffffff;
  color: #f56363;
  background-color: #ffffff;
}
.btn-primary {
  background-color: #e7a331;
  color: #ffffff;
  border: 2px solid #e7a331;
  text-transform: uppercase;
  border-radius: 4px;
}
.btn-primary:before,
.btn-primary:after {
  background-color: #ffffff;
}
.btn-primary:focus {
  background-color: #d6962c;
  border-color: #d6962c;
}
.btn-primary:hover {
  border-color: #d6962c;
  color: #fff;
}
/*
Extra height css
*/
.padding0 {
  padding: 0;
}
.center-content {
  text-align: center !important;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.padding-top-20 {
  padding-top: 1.33rem;
}
.padding-bottom-20 {
  padding-bottom: 1.33rem;
}
.padding-top-40 {
  padding-top: 2.66rem;
}
.padding-bottom-40 {
  padding-bottom: 2.66rem;
}
.padding-top-60 {
  padding-top: 5rem;
}
.padding-bottom-60 {
  padding-bottom: 5rem;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
/*padding*/
.padding-twenty {
  padding: 10px 0;
}
.padding-fourty {
  padding: 20px 0;
}
.padding-sixty {
  padding: 30px 0;
}
.padding-eighty {
  padding: 40px 0;
}
h1 span {
  color: #f56363;
  font-weight: 400;
}

/*for placeholder changes*/

/*input::-webkit-input-placeholder {
  color: #CACACA;
  font-size: 18px;
}
input::-moz-placeholder {
  color: #CACACA;
  font-size: 18px;
}
input:-ms-input-placeholder {
  color: #CACACA;
  font-size: 18px;
}*/

/* Header top menu css */

.header_top_menu {
  background: #fff;
  position: relative;
}

a.navbar-brand.our_logo {
  position: absolute;
  left: 0;
  top: -50px;
  width: 25%;
}



.header_top_menu .call_us_text a {
  color: #666;
  font-size: 15px;
  margin-left: 15px;
  padding: 5px;
  display: inline-block;
}
.header_top_menu .call_us_text a i {
  color: #e7a331;
  margin-right: 10px;
  font-size: 18px;
}

.head_top_social a {
  color: #e7a331;
  padding: 5px;
  display: inline-block;
}

/* main menu */
.menu-scroll {
  padding-top: 0px;
  padding-bottom: 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: #000;
  height: 80px;
}

.menu-scroll .header_top_menu {
  display: none;
}
.menu-scroll a.navbar-brand.our_logo {
  top: -16px;
}

.main_menu_bg {
  background: rgba(0, 0, 0, 0.45);
  height: 80px;
}
.navbar-default {
  background-color: black;
  /* transparent; */
  border-color: transparent;
}
.container-fluid {
  margin-top: 15px;
}
.main_menu_bg li a.booking {
  border: 1px solid #fff;
  border-radius: 4px;
  color: #e7a331 !important;
  padding: 5px 10px;
  margin-top: 8px;
}
.main_menu_bg li a:hover.booking {
  color: #da9b31 !important;
}

.main_menu_bg .navbar {
  margin-bottom: 0px;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #da9b31;
}

/* slider section */

.slider {
  background: url(../../public/img/flor01.jpg) center center no-repeat;
  background-size: cover;
}

.main_slider .main_slider_content h1 {
  font-size: 6rem;
  font-family: "Monotype corsiva", cursive;
  color: #fff;
  line-height: 6rem;
  margin: 0px 0px 20px 0px;
}
.main_slider_content {
  padding: 250px 0px 100px 0px;
  width: 70%;
  margin: 0 auto;
  color: #fff;
}

.slider_overlay {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  top: 0;
  left: 0;
}

/* Abouts Section css */

.abouts {
  background: url(../../public/img/about-bg.jpg) no-repeat center top scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.abouts_content {
  padding: 120px 0px;
  display: inline-block;
}
.single_abouts_text h4 {
  color: #000000;
}
.single_abouts_text h3 {
  color: #000000;
}

.construct {
  font-family: "Monotype Corsiva", cursive;
  font-weight: 300;
  margin: 0;
  font-weight: 300;
  margin-bottom: 0.9rem;
  font-size: 2.375rem;
  line-height: 2.5rem;
}

.under_construction {
  margin-left: 150px;
  text-align: center;
  font-size: 14px;
}

.extras {
  background: url(../../public/img/Natal_BG.jpg) no-repeat center top scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.extras_content {
  width: 100%;
  margin: 100px auto;
  padding: 100px 25%;
  display: block;
}

.extras_link {
  font-size: 2rem;
  font-family: "Monotype corsiva", cursive;
  color: #fff;
  line-height: 6rem;
  margin: 0px 0px 20px 0px;
}
.extras_link p {
  font-size: 2rem;
  font-family: "Monotype corsiva", cursive;
  color: #fff;
}

.extras_link a:link {
  color: #fff;
  text-decoration: underline;
}

.extras_link a:visited {
  color: white;
}

.extras_link a:hover {
  color: red;
}

.extras_link a:active {
  color: hotpink;
}

/* Features section css */
.features {
  /* background:url(../../public/img/ftbg.jpg) center center no-repeat; */
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.main_features_content_area {
  padding: 120px 0px;
  display: inline-block;
}
.main_features_content {
  /* background:url(../../public/img/ft.png) right center no-repeat; */
  padding: 40px;
  overflow: hidden;
}

.single_features_text {
  /* background:url(../../public/img/textbg.jpg) center center no-repeat; */
  padding: 30px;
  background-size: cover;
}

.single_features_text h3 {
  color: #000000;
}

.single_features_text h4 {
  color: #000000;
}
/* Portfolio Section css */
.portfolio {
  background: url(../../public/img/portfoliobg.jpg) center top no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}

.portfolio_content {
  padding-bottom: 120px;
  display: inline-block;
}
.portfolio .portfolio_content .head_title h3 {
  color: #000000;
}
.portfolio .portfolio_content .head_title h4 {
  color: #000000;
}

.head_title {
  padding-top: 100px;
  padding-bottom: 50px;
}

.head_title h2 {
  color: #fff;
}

.single_portfolio_text {
  display: inline-block;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.single_portfolio_text img {
  width: 100%;
}

.single_portfolio_text:hover .portfolio_img_overlay {
  top: 28px;
  left: 14px;
}

.portfolio_img_overlay {
  width: 90%;
  height: 80%;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin: 0 auto;
  top: -100%;
  left: 14px;
  position: absolute;
  transition: 0.6s;
}
.portfolio_img_overlay h6 {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.3125rem;
  line-height: 2rem;
}

.portfolio_img_overlay p.product_price {
  font-size: 2.5725rem;
  color: #fff;
  line-height: 3rem;
}

/*Amazing Deliceas Section css */
.ourPakeg .main_pakeg_content .head_title h3 {
  color: #fff;
}
.ourPakeg .main_pakeg_content .head_title h4 {
  color: #fff;
  font-family: "Monotype corsiva", cursive;
  font-size: 1.875rem;
}

.ourPakeg {
  background: url(../../public/img/apresenta-bg.jpg) no-repeat center top scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  padding-bottom: 120px;
}

.single_pakeg_one {
  /* background:url(../img/newsone.jpg) left center no-repeat; */
  background: url(../../public/img/flor02.jpg) left center no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}
.single_pakeg_two {
  /* background:url(../img/newstwo.jpg) left center no-repeat; */
  background: url(../../public/img/flor04.jpg) right center no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}
.single_pakeg_three {
  /* background:url(../img/newsthree.jpg) left center no-repeat; */
  background: url(../../public/img/flor05.jpg) left center no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

.single_pakeg_text {
  font-family: "Monotype corsiva", cursive;
  font-size: 24px;
  background: #fff;
  padding: 30px;
  text-align: left;
}

.single_pakeg_text ul li {
  padding: 15px 0px 0px 60px;
  /* margin-top: 20px; */
}

.single_pakeg_text_x ul li {
  /* background: url(../../public/img/pkli.png) no-repeat left center; */
  padding: 15px 0px 15px 60px;
  margin-top: 20px;
}

/* .ourPakeg .menu-item {
	margin: 15px 0;
	font-size: 18px;
} */
/*Amazing Deliceas Section css */
.menu {
  background: url(../../public/img/flor02.jpg) no-repeat center top scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  padding-bottom: 120px;
}

/* Menu Section */
#restaurant-menu {
  /* padding: 0 0 60px; */
  background: #444 url(../../public/img/flor16.jpg) center center no-repeat
    fixed;
  background-size: cover;
}
#restaurant-menu .section-title {
  /* background: #444 url(../img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
  padding: 65px 0 0 0;
}
#restaurant-menu .section-title h2 {
  color: #fff;
}
#restaurant-menu .overlay {
  /* padding: 0px 80px; */
  background: rgba(0, 0, 0, 0.7);
}
#restaurant-menu img {
  /* width: 300px;
	box-shadow: 15px 0 #a7c44c; */
}
#restaurant-menu h3 {
  padding: 10px 0;
  text-transform: uppercase;
}
#restaurant-menu .menu-section hr {
  margin: 0 auto;
}
#restaurant-menu .menu-section {
  margin: 0 20px 80px;
  /* background: #2e5366; */
}
#restaurant-menu .menu-section-title {
  font-size: 26px;
  display: block;
  font-weight: 500;
  color: white;
  margin: 20px 0;
  text-align: center;
}
#restaurant-menu .menu-item {
  margin: 15px 0;
  font-size: 18px;
  color: white;
}
#restaurant-menu .menu-item-name {
  font-weight: 600;
  font-size: 17px;
  color: white;
  /* border-bottom: 2px dotted rgb(213, 213, 213); */
}
#restaurant-menu .menu-item-cover {
  font-family: "roboto";
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  /* letter-spacing: 1px; */
}
#restaurant-menu .menu-item-description {
  font-style: italic;
  font-size: 15px;
}
#restaurant-menu .menu-item-price {
  float: right;
  font-weight: 600;
  color: white;
  margin-top: -26px;
}

.restaurant-collapse {
  background: rgba(0, 0, 0, 0.7);
}

.restaurant-collapse .rci-toggle:after {
  /* symbol for "opening" panels */
  font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
  /* content: "\e113"; /* adjust as needed, taken from bootstrap.css */
  content: "\e117"; /* adjust as needed, taken from bootstrap.css */
  float: right; /* adjust as needed */
  color: white; /* adjust as needed */
}

.restaurant-collapse .rci-toggle.collapsed:after {
  /* symbol for "collapsed" panels */
  /* content: "\e114"; /* adjust as needed, taken from bootstrap.css */
  content: "\e118"; /* adjust as needed, taken from bootstrap.css */
}

/* Cafetaria Section */
#cafetaria-menu {
  /* padding: 0 0 60px; */
  background: #444 url(../../public/img/cafetaria_fundo1.jpg) center center
    no-repeat fixed;
  background-size: cover;
}
#cafetaria-menu .section-title {
  /* background: #444 url(../img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
  padding: 65px 0 0 0;
}
#cafetaria-menu .section-title h2 {
  color: #fff;
}
#cafetaria-menu .overlay {
  /* padding: 0px 80px; */
  background: rgba(0, 0, 0, 0.7);
}
#cafetaria-menu img {
  /* width: 300px;
	box-shadow: 15px 0 #a7c44c; */
}
#cafetaria-menu h3 {
  padding: 10px 0;
  text-transform: uppercase;
}
#cafetaria-menu .menu-section hr {
  margin: 0 auto;
}
#cafetaria-menu .menu-section {
  margin: 0 20px 80px;
  /* background: #2e5366; */
}
#cafetaria-menu .menu-section-title {
  font-size: 26px;
  display: block;
  font-weight: 500;
  color: white;
  margin: 20px 0;
  text-align: center;
}
#cafetaria-menu .menu-item {
  margin: 10px 0;
  font-size: 12px;
  color: black;
}
#cafetaria-menu .menu-item-name {
  font-weight: 600;
  font-size: 17px;
  color: white;
  /* border-bottom: 2px dotted rgb(213, 213, 213); */
}
#cafetaria-menu .menu-item-cover {
  font-family: "roboto";
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  /* letter-spacing: 1px; */
}
#cafetaria-menu .menu-item-description {
  font-style: italic;
  font-size: 15px;
}
#cafetaria-menu .menu-item-price {
  float: right;
  font-weight: 600;
  color: white;
  margin-top: -26px;
}

/* Ementa Section */
#restaurant-ementa {
  /* padding: 0 0 60px; */
}
#restaurant-ementa .section-title {
  /* background: #444 url(../img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
  padding: 65px 0 0 0;
}
#restaurant-ementa .section-title h2 {
  color: black;
}
#restaurant-ementa .overlay {
  /* padding: 0px 80px; */
  background: rgba(0, 0, 0, 0.7);
}

#restaurant-ementa img {
  width: 300px;
  box-shadow: 15px 0 #a7c44c;
}
#restaurant-ementa h3 {
  padding: 10px 0;
  text-transform: uppercase;
}
#restaurant-ementa .menu-section hr {
  margin: 0 auto;
  color: black;
}
#restaurant-ementa .menu-section {
  margin: 0 20px 80px;
  color: black;
  /* background: #2e5366; */
}
#restaurant-ementa .menu-section-title {
  font-family: "Monotype Corsiva", cursive;
  font-size: 18px;
  display: block;
  font-weight: 500;
  color: black;
  margin: 10px 0 0 0;
  text-align: center;
}
#restaurant-ementa .menu-item {
  margin: 10px 0;
  font-size: 12px;
  color: black;
}
#restaurant-ementa .menu-item-name {
  font-family: "roboto", sans-serif;
  font-size: 12px;
  color: black;
  /* font-weight: bold; */
  text-align: left;
}
#restaurant-ementa .menu-item-tab {
  /* font-weight: 600; */
  font-size: 14px;
  color: black;
  border-bottom: 2px dotted grey;
}

#restaurant-ementa .menu-item-description {
  font-style: italic;
  color: black;
}

#restaurant-ementa .menu-item-price {
  float: right;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  margin-top: -18px;
}

#menu .thumbnail {
  background: transparent;
  border: 0;
}
#menu .thumbnail .menu-img {
  display: inline-flex;
  position: relative;
  width: 100px;
  height: 50px;
}
#menu .thumbnail .menu-img:before {
  display: block;
  content: "";
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
#menu .thumbnail .caption {
  padding-top: 10px;
}
#menu .thumbnail .caption p {
  color: rgba(255, 255, 255, 0.7);
  padding: 0 10px;
  font-size: 15px;
}
#menu .thumbnail:hover {
  cursor: pointer;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
}
.menu-modal-img {
  display: inline-flex;
  position: relative;
  width: 150px;
  height: 100px;
}

/* Mobail Apps Section css */

.mobailapps {
  /* background:url(../../public/img/appsbg.jpg) top center no-repeat scroll; */
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.main_mobail_apps_content {
  margin: 80px 0px;
  color: #fff;
  overflow: hidden;
}

.single_monail_apps_text {
  margin-top: 120px;
}
.single_monail_apps_text h4 {
  font-size: 1.875rem;
  margin-bottom: 30px;
}

.single_monail_apps_text h1 {
  font-size: 5rem;
  text-transform: uppercase;
  color: #fff;
  line-height: 5rem;
}
.single_monail_apps_text h1 span {
  font-size: 1.8rem;
  color: #fff;
  display: block;
}

.single_monail_apps_text {
  margin-left: -10px;
}
.single_monail_apps_text a {
  margin-top: 50px;
  margin-left: 10px;
  display: inline-block;
}
.single_monail_apps_text a img {
  max-width: 90%;
}

/* Footer Section CSS */
.footer_widget {
  background: url(../../public/img/footerbg.jpg) center top no-repeat scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.footer_widget_content {
  color: #fff;
}
.single_widget {
  padding-bottom: 50px;
  padding-top: 80px;
}

.single_widget h3 {
  font-size: 1.875rem;
  font-family: "Monotype corsiva", cursive;
}
.single_widget_info p {
  margin-top: 20px;
}
.single_widget_info p span {
  display: block;
}
.single_widget_info p span.phone_email {
  margin-top: 15px;
}
.footer_social_icon {
  margin-top: 10px;
}
.footer_social_icon a {
  padding: 5px;
}
.footer_social_icon a i {
  font-size: 25px;
  color: #e7a331;
}

.single_widget_info p span.date_day {
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 600;
}
.footer {
  background: #000;
}
.footer .copyright p {
  padding: 25px;
  color: #fff;
}
.footer .copyright p i {
  padding: 0px 10px;
  color: #ff0000;
}
.footer .copyright p a {
  padding: 0px 10px;
  color: green;
}

#footer {
  background: #262626;
  padding: 50px 0 0 0;
}
#footer h3 {
  color: #ff5c5c;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
#footer .copyrights {
  padding: 20px 0;
  margin-top: 50px;
  background: #2e5366;
}
#footer .social {
  margin: 20px 0 30px 0;
}
#footer .social ul li {
  display: inline-block;
  margin: 0 20px;
}
#footer .social i.fa {
  font-size: 26px;
  padding: 4px;
  color: #fff;
  transition: all 0.3s;
}
#footer .social i.fa:hover {
  color: #eee;
}
#footer p {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
}
#footer a {
  color: #f6f6f6;
}
#footer a:hover {
  color: #333;
}

/* vinhos Section */
#vinhos {
  /* color: #fff; */
  background: #444 url(../../public/img/garrafeira.jpg) center top no-repeat
    fixed;
  background-size: cover;
}
#vinhos .overlay {
  /* padding: 120px 0 80px 0; */
  padding: 20px 0;
  padding-bottom: 20px;
  background: rgba(0, 0, 0, 0.6);
}
#vinhos h2,
#team p {
  color: #fff;
}
#vinhos hr {
  background: #fff;
}
#vinhos h3 {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0;
}
#vinhos img {
  width: 280px;
}
#vinhos .thumbnail {
  background: transparent;
  border: 0;
}
#vinhos .thumbnail .vinhos-img {
  display: inline-flex;
  position: relative;
  height: 400px;
}
#vinhos .thumbnail .vinhos-img:before {
  display: block;
  content: "";
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
#vinhos .thumbnail .caption {
  padding-top: 10px;
}
#vinhos .thumbnail .caption p {
  color: rgba(255, 255, 255, 0.7);
  padding: 0 10px;
  font-size: 15px;
}
#vinhos .thumbnail:hover {
  cursor: pointer;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
}
.vinhos-modal-img {
  display: inline-flex;
  position: relative;
  width: 50px;
  height: 100px;
}

/* Bar Section */
#bar {
  /* color: #fff; */
  background: #444 url(../../public/img/bar_bg.png) center top no-repeat fixed;
  background-size: cover;
}
#bar .overlay {
  /* padding: 120px 0 80px 0; */
  padding: 20px 0;
  padding-bottom: 20px;
  background: rgba(0, 0, 0, 0.6);
}
#bar h2,
#team p {
  color: #fff;
}
#bar hr {
  background: #fff;
}
#bar h3 {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0;
}
#bar img {
  width: 200px;
}
#bar .thumbnail {
  background: transparent;
  border: 0;
}
#bar .thumbnail .bar-img {
  display: inline-flex;
  position: relative;
  height: 180px;
}
#bar .thumbnail .bar-img:before {
  display: block;
  content: "";
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
#bar .thumbnail .caption {
  padding-top: 10px;
}
#bar .thumbnail .caption p {
  color: rgba(255, 255, 255, 0.7);
  padding: 0 10px;
  font-size: 15px;
}
#bar .thumbnail:hover {
  cursor: pointer;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
}
.bar-modal-img {
  display: inline-flex;
  position: relative;
  width: 100px;
  height: 100px;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

/* scrollup */

.scrollup {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  opacity: 0.3;
  position: fixed;
  bottom: 20px;
  right: 25px;
  color: #fff;
  cursor: pointer;
  background-color: #000;
  z-index: 1000;
  transition: opacity 0.5s, background-color 0.5s;
  -moz-transition: opacity 0.5s, background-color 0.5s;
  -webkit-transition: opacity 0.5s, background-color 0.5s;
}

.scrollup:hover {
  background: #e7a331;
  opacity: 1;
}

.scrollup i {
  font-size: 13px;
  position: absolute;
  opacity: 1;
  color: #fff;
  left: 50%;
  top: 50%;
  margin-top: -7px;
  margin-left: -6px;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .under_construction {
    margin-left: 50px;
    text-align: center;
    font-size: 14px;
  }

  #menu .thumbnail .menu-img {
    width: 80px;
    height: 60px;
  }
}

@media (max-width: 991px) {
  .extras_content {
    padding: 0px 0px 0px 0px;
    margin: 100px auto;
  }
  .under_construction {
    margin-left: 12px;
    text-align: center;
    font-size: 12px;
  }
  .single_pakeg_text {
    margin-top: 300px;
  }
  #menu .thumbnail .menu-img {
    width: 80px;
    height: 60px;
  }
}

@media (max-width: 767px) {
  .main_slider .main_slider_content h1 {
    font-size: 5rem;
    font-family: "Monotype corsiva", cursive;
    /* color: #fff; */
    line-height: 4rem;
    /* margin:0px 0px 20px 0px; */
  }

  .main_slider_content {
    padding: 200px 0px 100px 0px;
    /* width:70%;
  	margin:0 auto;
  	color:#fff; */
  }

  .extras_content {
    padding: 0px 0px 0px 0px;
    margin: 100px auto;
  }

  #restaurant-menu .menu-item-cover {
    font-size: 16px;
  }
  #restaurant-ementa .menu-item-name {
    font-family: "roboto", sans-serif;
    font-size: 10px;
    color: black;
    /* font-weight: bold; */
    text-align: left;
  }
  .abouts_content {
    padding: 80px 0px;
    /* display:inline-block; */
  }

  .single_widget {
    padding-bottom: 10px;
    padding-top: 40px;
  }

  .single_pakeg_text {
    margin-top: 300px;
  }

  .single_pakeg_text ul li {
    padding: 15px 0px 0px 40px;
    /* margin-top: 20px; */
  }
  #restaurant-menu .menu-item-cover {
    font-size: 14px;
  }
  #restaurant-ementa .menu-item-cover {
    font-size: 14px;
  }
  #cafetaria-menu .menu-item-cover {
    font-size: 14px;
  }
  #vinhos .thumbnail .vinhos-img {
    width: 120px;
    height: 200px;
  }
  #bar .thumbnail .bar-img {
    width: 120px;
    height: 120px;
  }
  #menu .thumbnail .menu-img {
    width: 60px;
    height: 40px;
  }
}

@media (max-width: 479px) {
  .main_slider .main_slider_content h1 {
    font-size: 4rem;
    font-family: "Monotype corsiva", cursive;
    /* color: #fff; */
    line-height: 4rem;
    /* margin:0px 0px 20px 0px; */
  }

  .main_slider_content {
    padding: 200px 0px 100px 0px;
    /* width:70%;
  	margin:0 auto;
  	color:#fff; */
  }

  .construct {
    font-size: 1.8rem;
  }

  .extras_content {
    padding: 0px 0px 0px 0px;
    margin: 100px auto;
  }

  .extras_link {
    font-size: 1.5rem;
  }
  .extras_link p {
    font-size: 1.5rem;
  }

  .abouts_content {
    padding: 80px 0px;

    /* display:inline-block; */
  }

  .abouts p {
    font-size: 13px;
  }

  #restaurant-menu .menu-item-cover {
    font-size: 16px;
  }
  #restaurant-ementa .menu-item-name {
    font-family: "roboto", sans-serif;
    font-size: 10px;
    color: black;
    /* font-weight: bold; */
    text-align: left;
  }
  .ourPakeg {
    padding-bottom: 80px;
  }

  .single_pakeg_one {
    /* background:url(../img/flor22.jpg) left center no-repeat;     */
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
  }
  .single_pakeg_two {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
  .single_pakeg_three {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }

  .single_pakeg_text {
    margin-top: 300px;
    padding-left: 10px;
    font-size: 20px;
  }

  .single_pakeg_text ul li {
    padding: 5px 0px 0px 10px;
  }

  #restaurant-menu .menu-item-cover {
    font-size: 12px;
  }
  #restaurant-ementa .menu-item-cover {
    font-size: 12px;
  }
  #cafetaria-menu .menu-item-cover {
    font-size: 12px;
  }
  #vinhos .thumbnail .vinhos-img {
    width: 120px;
    height: 200px;
  }
  #bar .thumbnail .bar-img {
    width: 120px;
    height: 100px;
  }
  #menu .thumbnail .menu-img {
    width: 60px;
    height: 40px;
  }
}

.margem {
  margin-top: 60px;
}

.margem_s {
  margin-top: 110px;
}

.fix-top {
  position: sticky;
  padding-top: 2px;
  background-color: #f6f0e7;
  top: 0;
  z-index: 100;
}

.drop {
  margin-left: 5px;
  padding-top: 5px;
  /* opacity: 0.8; */
}

.more {
  color: #742c20;
  background-color: #f6f0e7;
  opacity: 1;
}
