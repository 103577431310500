// @import url('https://fonts.googleapis.com/css?family=Raleway:400, 700, 800');

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* html {
  height: 100%;
}

body {
  height: 100%;
  // background: #444;
  font-family: 'Raleway', Arial, sans-serif;
} */

#app {
  height: 100%;
}

main a {
  color: #4e4a46;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #c94e50;
  }
}

main {
  color: #fffce1;
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}

h1 {
  font-weight: 800;
  font-size: 3.75em;
}

.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}

.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;

  a {
    display: inline-block;
    margin: 0.75em;
    padding: 1.35em 1.1em;
    width: 15em;
    background: #fffce1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    border-top-left-radius: 20px 50px;
    border-top-right-radius: 20px 50px;
    border-bottom-right-radius: 20px 50px;
    border-bottom-left-radius: 20px 50px;
    cursor: pointer;

    &.current-demo {
      background: #c94e50;
      color: #fffce1;
    }
  }
}

.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;

  &.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.active {
    background: #c94e50;
    color: #fffce1;
  }
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }

  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }

  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}

//
// Burger menu custom styles
//
.bm-burger-button {
  position: fixed;
  // position: sticky;
  width: 50px;
  height: 50px;
  right: 15px;
  top: 20px;
  color: white;
  background-color: grey;
}
// @media screen and (max-width: 100em) {
//   .bm-burger-button {
//      top: 5%;
//     //  width: 50px;
//     //  height: 50px;
//   }
// }

// @media screen and (max-width: 80em) {
//   .bm-burger-button {
//      top: 1.5%;
//     //  width: 50px;
//     //  height: 50px;
//   }
// }

// @media screen and (max-width: 50em) {
//   .bm-burger-button {
//      top: 4%;
//     //  width: 50px;
//     //  height: 50px;
//   }
// }

// Outline for burger button focus state
.bm-burger-button button:focus {
  outline: 2px solid white;
  outline-offset: 8px;
}

// Background color for burger bars focus state
.bm-burger-button {
  button:focus + span {
    span.bm-burger-bars {
      background-color: white;
    }
  }
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu {
  background: #373a47;

  a {
    color: #b8b7ad;

    &:hover,
    &:focus {
      color: white;
    }
  }
}

.bm-item-list {
  padding: 0.8em;
  font-size: 1.5em;

  span {
    margin-left: 10px;
    font-weight: 700;
  }
}


@media (max-width: 100em) and (min-width: 71em) {
  .bm-item-list {
    font-size: 2.5em;
  }
  .bm-burger-button {
    position: fixed;
    width: 60px;
    height: 60px;
    right: 15px;
    top: 20px;
    color: white;
    background-color: grey;
  }
}

@media (max-width: 70em) {
  .bm-burger-button {
    position: fixed;
    width: 80px;
    height: 80px;
    right: 15px;
    top: 20px;
    color: white;
    background-color: grey;
  }
}


.bm-item-list a {
  padding: 0.8em;
  font-size: 2.5em;

  span {
    margin-left: 10px;
    font-weight: 700;
  }
}

.bm-item:focus {
  outline: none;
}
